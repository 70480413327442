import axios from 'axios'

const axiosApi = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
});

axiosApi.interceptors.request.use(config => {
  const token = localStorage.getItem('@calypso-b2b-token');
  if (token) {
    config.headers['x-token'] = token
  }

  return config
})

export default axiosApi